
/**
 * @package     Modal
 * @version     1.0
 * @author      Trevor Wagner
 */
 export default class Modal{

    constructor() {
        this.modal = {
            el:null,
            triggers:null,
            close: null,
            body:null,
            title: null,
        }
        
        this.flags = {
            dataLoaded:false
        }

        this.init();

		return this;
    }

    init() {
        const self = this;
        
        const modal    = $('[data-modal]');
        const triggers = $('[data-modal-trigger]');
        
        if(modal.length > 0 && triggers.length > 0){
            self.modal.el       = modal;
            self.modal.triggers = triggers;

            const close = self.modal.el.find('[data-modal-close]');
            const body  = self.modal.el.find('[data-modal-body]');
            const title = self.modal.el.find('[data-modal-title]');

            self.modal.title = title;
            self.modal.close = close;
            self.modal.body  = body;

            self.setObservers();
        }
    }

    setObservers() {
        const self = this;

        self.modal.triggers.each(function(){
            let trigger = $(this);

            trigger.on('click', function(e){
                e.preventDefault();
                
                self.open();
            });
        })

        if(self.modal.close.length > 0){
            self.modal.close.on('click', function(e){
                e.preventDefault();
                self.close();
            });
        }
    }

    open(){
        const self = this;
        self.lockBody(true);
        self.modal.el.attr('open', true);
    }

    close(){
        const self = this;
        self.lockBody(false);
        self.modal.el.removeAttr('open');
    }

    lockBody(lockIt){
		lockIt ? $('body').addClass('locked') : $('body').removeClass('locked');
	}
}