import { defineStore } from 'pinia';

export const globalStore = defineStore('globalStore', {
  state: () => {
    const flag = wpCore.flags;  
    return {
        favoritesEnabled:    flag.favoritesEnabled,
        googleMapsEnabled:   flag.googleMapsEnabled,
        appointmentsEnabled: flag.appointmentsEnabled,
        userAuthenticated:   flag.isUserAuthenticated,
        isMapViewEnabled:    flag.isMapViewEnabled,
        siteKey:             wpCore.siteKey,
        gmapKey:             wpCore.gmapK,
        reviewsKey:          wpCore.reviewsKey,
        links:{
          signInLink: wpCore.links.signInLink,
          signOutLink: wpCore.links.signOutLink,
          passResetLink: wpCore.links.passResetLink,
        },
        user:{
          uid: wpCore.user.uid,
          favoriteCount: wpCore.user.favoriteCount
        }
    }
  }
});