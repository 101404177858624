import { defineStore } from 'pinia';

export const favoritesStore = defineStore('favoritesStore', {
  state: () => {
    return { 
      favoriteCount: wpCore.user.favoriteCount,
    }
  },
  actions: {
    addToFavorites: function(uid, lid, cb){
      const self = this;

      if(uid && lid){
        // Make request to backend
        $.post({
          method: 'POST',
          url: wpCore.ajaxUrl,
          data: {
            action:'add_favorited_property',
            user_id: uid, 
            listing_id: lid
          },
        }).then(function (resp) {
          const response = JSON.parse(resp);

          if (response.status) {
            self.$patch({
              favoriteCount: response.data.count
            })
            
            cb('success', response)
          }
        }).catch(function (err) {
          console.log(err)
          cb('error', err)
        })
      }
    },
    removeFavorite: function(uid, lid, cb){
      const self = this;

      if(uid && lid){
        // Make request to backend
        $.post({
          method: 'POST',
          url: wpCore.ajaxUrl,
          data: {
            action:'remove_favorited_property',
            user_id: uid, 
            listing_id: lid
          },
        }).then(function (resp) {
          const response = JSON.parse(resp);

          if (response.status) {
            self.$patch({
              favoriteCount: response.data.count
            })

            cb('success', response)
          }
          
        }).catch(function (err) {
          console.log(err)
          cb('error', err)
        })
      }
    }
  }
});