<template>

  <a v-if="!userAuthenticated" :href="signInLink">Sign In</a>

  <div v-if="userAuthenticated && favoritesEnabled">
    <span class="favoriteIcon" :class="favoriteCount >= 1 ? 'hasItems' : ''">
      <span class="material-symbols-outlined">favorite</span>
      <small v-if="favoriteCount >= 1">{{ favoriteCount }}</small>
    </span>
  </div>

</template>

<script>
import { mapState } from 'pinia';
import { favoritesStore } from '../stores/favorites';

import { globalStore } from "../stores/global";

export default {
  name: "FavoritesHeader",
  components: {},
  props: {},
  data() { },
  computed: {
    ...mapState(globalStore, {
      favoritesEnabled: store => store.favoritesEnabled,
      userAuthenticated: store => store.userAuthenticated,
      signInLink: store => store.signInLink,
    }),
    ...mapState(favoritesStore, {
      favoriteCount: store => store.favoriteCount
    })
  },
};
</script>

<style scoped>
</style>
