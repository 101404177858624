import Modal from './core/modal';
import MobileNav from './core/mobileNav';
import ScrollCTA from './core/scrollCta';
import FavoritesHeader from '../../vue/apps/favorites';
import AjaxForm from './core/AjaxForm';
import Masonry from 'masonry-layout';

(() => {
	'use strict';

	$(document).ready(() => {
		const favoritesHeaderEl = document.querySelector('[data-favorites-header]');
		const hasRecaptchas = $('.g-recaptcha').length > 0 ? true : false;
		const masonry = document.querySelector('.masonry-grid');

		new Modal();
		new MobileNav();
		new ScrollCTA();

		if (favoritesHeaderEl) {
			new FavoritesHeader(favoritesHeaderEl);
		}

		if (masonry) {
			const msnry = new Masonry(masonry, {
				// options
				itemSelector: '.grid-item',
				gutter:14,
				fitWidth: true
			});
		}

		if (hasRecaptchas) {
			injectScript('https://www.google.com/recaptcha/api.js?onload=recaptchaLoaded')
				.then(() => {
					//console.log('recaptcha script loaded');
					let forms = $('[data-ajax-form]');

					if (forms.length > 0) {
						forms.each(function () {
							const form = $(this);
							let ajaxForm = new AjaxForm(form, wpCore.ajaxUrl);
							ajaxForm.setObservers(form, wpCore.ajaxUrl);
						})
					}
				}).catch(error => {
					console.error(error);
				});
		}
	});
})();
