/**
 * @package     ScrollCTA
 * @version     1.0
 * @author      Trevor Wagner
 */
 export default class ScrollCTA {

    constructor(el) {
        this.scrollCtas = null;
        this.init();
        return this;
    }

    init(el) {
        const self = this,
              scrollCtas = $('[data-scroll-cta]');

        if (scrollCtas.length) {
            self.scrollCtas = scrollCtas;
            self.setObservers();
        }
    }

    setObservers() {
        const self = this;

        self.scrollCtas.each(function(){
            const el = $(this);

            if(el.length > 0){
                el.on('click', function (e, data) {
                    e.preventDefault();
                    const id = $(this).data('scrollCta');
                    if( $('#' + id).length > 0 ){
                        self.scrollToElement($('#' + id));
                    }
                });
            } 
        });

    }

    scrollToElement(el) {
        $(el).get(0).scrollIntoView();
    }
}