/**
 * @package     ReCaptcha
 * @version     1.0
 * @author      Trevor Wagner
 */
export default class ReCaptcha {

    constructor(captcha, siteKey) {
        this.recaptcha = null;
        this.siteKey = null;        

        this.init(captcha, siteKey);
        return this;
    }

    init(captcha, siteKey) {
        const self = this;
        
        if(captcha){
            self.recaptcha = captcha;
            self.siteKey = siteKey;
            self.initRecaptchaBox(siteKey);
        }
    }

    initRecaptchaBox(siteKey) {
        const self = this;        

        if (siteKey) {
            self.recaptcha = grecaptcha.render({
                size:'checkbox',
                siteKey: siteKey
            })
        }
    }
}