/**
 * @package     FormMessage
 * @version     1.0
 * @author      Trevor Wagner
 */
export default class FormMessage {

    constructor(el) {
        this.el = null;
        return this;
    }

    init(el) {
        const self = this;

        if (el.length) {
            self.el = $(el);
            self.setObservers();
        }
    }

    setObservers() {
        const self = this;

        $(document).on('core:message:init', function (e, data) {
            self.init(data.formMessage);
        })

        $(document).on('core:message:show', function (e, data) {
            self.show(data);
        })

        $(document).on('core:message:hide', function (e) {
            self.hide();
        })

        if (self.el != null) {
            self.el.on('click', function (e) {
                self.hide();
            })
        }
    }

    show(data) {
        const self = this,
              resp = data.resp;

        if ('message' in resp) {
            self.el.html(resp.message)
                .addClass('active')
                .addClass(resp.status ? 'success' : 'error');
            
                self.el.get(0).scrollIntoView();
        }
    }

    hide() {
        const self = this;
        self.el.removeClass('active success error info').text('');
    }
}