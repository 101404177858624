import FormMsg from './formMsg.js';
import ReCaptcha from './reCaptcha.js';

/**
 * @package     AjaxForm
 * @version     1.0
 * @author      Trevor Wagner
 */
export default class AjaxForm {

    constructor(form, url) {

        this.form = {
            el: null,
            action: null,
            submit: null,
            url: null,
            recaptcha: null
        };

        this.data  = { formData: null };
        this.flags = { canSubmit: false, hasRecaptcha:false, hasConfirmField: false };

        let captcha = form.find('.g-recaptcha');
        
        if(captcha.length > 0){
            this.flags.hasRecaptcha = true;
            this.form.recaptcha     = new ReCaptcha(captcha, captcha.data('siteKey'));
        }

        const confirmFields = form.find('[data-match-value]');

        if(confirmFields.length > 0){
            this.flags.hasConfirmField = true;
        }
        
        return this;
    }

    init(form, url) {
        if (form.length > 0) {
            this.form.el     = form;
            this.form.submit = form.find('button[type="submit"]');
            this.form.action = form.data('action');
            this.form.url    = url;

            this.collectData();

            if(this.flags.hasConfirmField){
                this.checkConfirmFields();
            }

            this.form.submit.html('<i class="fa fa-fw fa-spin fa-spinner"></i>');
            
            if(this.flags.hasRecaptcha){
                const self = this;
                const token = grecaptcha.getResponse();

                if(token){
                
                    $.ajax({
                        url: wpCore.ajaxUrl,
                        method:'POST',
                        data:{
                            action: 'verify_recaptcha',
                            token: token
                        }
                    }).done(function(response){
                        const resp = JSON.parse(response);

                        if(resp.data.success){
                            self.makeRequest();
                        } else {
                            $(document).trigger('core:message:show', { resp: resp });
                            grecaptcha.reset();
                        }
                    }).fail(function(err){
                        console.log(err)
                        grecaptcha.reset();
                    })
                } else {
                    grecaptcha.reset();
                }
            } else {
                this.makeRequest(this);     
            }

            
        }
    };

    setObservers(form, ajaxUrl) {
        const self = this;

        $('[data-ajax-form]').on('submit', function (e) {
            e.preventDefault();

            const msgDiv = form.find('[data-form-msg]');
            
            if(msgDiv.length > 0){
                const formMsg = new FormMsg(msgDiv);
                formMsg.setObservers();
                $(document).trigger('core:message:init', { formMessage: msgDiv }).trigger('core:message:hide');
            }
        
            self.init(form, ajaxUrl);
        });
    };

    collectData(){
        this.data.formData = this.form.el.serialize();
    };

    checkConfirmFields(){
        const self = this;
        const confirmFields = self.form.el.find('[data-match-value]');

        if(confirmFields.length > 0){

            confirmFields.each(function(){
                const field = $(this);
                const fieldID = $(this).data('matchValue');
                const fieldToCheck = $('#' + fieldID + ' input[type="password"]');

                if(fieldToCheck.length > 0){
                    const originalVal = fieldToCheck.val();
                    const confirmVal  = field.find('input[type="password"]').val();

                    if(originalVal !== confirmVal){
                        self.flags.canSubmit = false;
                        $(document).trigger('core:message:show', { response : { status: false, message: 'Please match the appropriate password requirements and try again.'}} );
                        return false;
                    }
                }
            })

            self.flags.canSubmit = true;
        }
    }

    makeRequest() {
        const self = this;

        // Ajax POST call using native DW library.
        $.ajax({
            method: 'POST',
            action: this.form.action,
            url: this.form.url,
            data: this.data.formData,
            success: function(resp){
                self.formSuccess(resp, self);
            }
        });
    };

    formSuccess(resp, inst) {
        const self = this;
        inst.form.submit.html('Submit');

        if(self.flags.hasRecaptcha){
            grecaptcha.reset();
        }
        
        try {
            const response = JSON.parse(resp);

            $(document).trigger('core:message:show', { resp: response });

            if ('redirectURL' in response && response.redirectURL !== null) {
                window.location.href = response.redirectURL;
            }
        } catch (e) {
            console.log(e)
        }
    };
}